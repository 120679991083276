import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb } from "../components/Section"
import { BulletIcon } from "../components/Icons"
import BreakpointUp from "../components/Media/BreakpointUp"
import FormCard from "../components/FormCard"
import Address from "../components/Address"

const BackgroundImage = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const Mapbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 400px;
  position: relative;
  ${BreakpointUp.md`
		min-height:500px;		
		position:inherit;
	`}
  ${BreakpointUp.lg`
		min-height:650px;		
  `}
  .gatsby-image-wrapper {
    width: 100%;
  }
`

const TopHeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${BreakpointUp.lg`
    margin:0 -30px;
  `}
`
const TopHeaderLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`
        flex: 0 0 50%;
        max-width: 50%;
    `}
  ${BreakpointUp.lg`
        padding:0 30px;
        flex: 0 0 50%;
        max-width: 50%;
    `}
`
const TopHeaderRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`
        padding-top:50px;
        flex: 0 0 50%;
        max-width: 50%;
    `}
  ${BreakpointUp.lg`
        padding:0 30px;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
    &:before, &:after {
    content: "";
    position: absolute;
    transform: matrix(-0.87, 0.5, -0.5, -0.87, 0, 0);
    display: none;
    ${BreakpointUp.lg`
            display:block;            
        `}
  }
  &:before {
    width: 83px;
    height: 83px;
    border: 8px solid #fff;
    top: -10px;
    right: 50px;
  }
  &:after {
    width: 50px;
    height: 50px;
    border: 5px solid #211f28;
    top: -37px;
    right: 130px;
  }
  .card {
    margin-bottom: 0;
  }
`

const TopHeaderTitle = styled.h1`
  margin: 0 0 5px;
`
const TopHeaderBody = styled.div`
  position: relative;
  &:after {
    content: "";
    width: 42px;
    height: 42px;
    border: 4px solid #211f28;
    transform: matrix(-0.86, -0.52, 0.52, -0.86, 0, 0);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
    ${BreakpointUp.md`    
      display:block;
    `}
  }
`
const TopHeaderContent = styled.div`
  ${BreakpointUp.md`    
        padding:0 0 50px;      
    `}
  .list-info {
    li {
      font-weight: 700;
      .text,
      a {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
`
const TopHeaderText = styled.div`
  p {
    font-size: 16px;
    line-height: 28px;
    ${BreakpointUp.lg`
        font-size: 18px;
        line-height: 30px;
        margin-bottom:30px;
    `}
  }
  & a {
    font-weight: 600;
    color: #6a6a6a;
    &:hover,
    &:focus {
      color: #000;
    }
  }
`

function ContactUsPage({ data, intl }) {
  const pageData = data.contentfulContactUsPage
  return (
    <Layout>
      <SEO title="Contact Us" />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <Link to="/about-us">
            {intl.formatMessage({ id: "About Us" })} <BulletIcon />
          </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <Section pt="250px" pb="0" xpt="90px" xpb="0" bgColor="#F6F6F6">
        <div className="container">
          <TopHeaderSection>
            <TopHeaderLeft>
              <TopHeaderBody>
                <TopHeaderContent>
                  <TopHeaderTitle>{pageData.pageName}</TopHeaderTitle>
                  <TopHeaderText>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          pageData.heroDescription.childMarkdownRemark.html,
                      }}
                    />
                  </TopHeaderText>
                  <Address />
                </TopHeaderContent>
              </TopHeaderBody>
            </TopHeaderLeft>
            <TopHeaderRight>
              <FormCard intl={intl} />
            </TopHeaderRight>
          </TopHeaderSection>
        </div>
      </Section>
      <Section pt="0" pb="0" mt="-135px" xmt="-70px" bgColor="#F9F9F9">
        <Mapbox>
          <BackgroundImage>
            <Img
              fluid={pageData.mapImage.fluid}
              alt={pageData.mapImage.description}
            />
          </BackgroundImage>
        </Mapbox>
      </Section>
    </Layout>
  )
}

export default injectIntl(ContactUsPage)

export const pageQuery = graphql`
  query ContactUsPageQuery($locale: String) {
    contentfulContactUsPage(node_locale: { eq: $locale }) {
      node_locale
      metaTitle
      metaDescription
      pageName
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      mapImage {
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
    }
  }
`
